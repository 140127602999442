import { LogoSpinner } from '@newfront-insurance/core-ui';

/**
 * This page only exists to support the redirects. The PostLoginRedirect provider will
 * catch this path and redirect the user to the correct app.
 *
 * Why didn't we just put the redirect logic here?
 * This page is rendered inside of all of the content providers and layouts in _app.tsx.
 * This means that the AppLayout will be shown when we don't actually have an app chosen.
 * We don't want that app layout to be visible just yet so it feels like a seamless transition.
 * It also means we can trigger the redirect before a lot of other expense providers run, meaning
 * it will happen faster.
 */
export default function Redirect(): JSX.Element {
  return <LogoSpinner />;
}
